:root { 
--gray_900_02:#051f28; 
--gray_900_03:#05202b; 
--gray_500:#a5a5a5; 
--blue_gray_100:#d9d9d9; 
--gray_500_7f:#a5a5a57f; 
--gray_500_4c:#a5a5a54c; 
--blue_A700:#306aff; 
--gray_800:#404040; 
--gray_900:#052029; 
--gray_900_01:#00282d; 
--white_A700_90:#ffffff90; 
--blue_gray_900_a2:#214145a2; 
--light_blue_500:#0d99ff; 
--black_900_3f:#0000003f; 
--blue_gray_900_90:#21414590; 
--black_900:#000000; 
--gray_900_a2:#051f28a2; 
--red_A400:#ff0d2a; 
--white_A700:#ffffff; 
--gray_900_90:#05202b90; 
--black_900_26:#00000026; 
}