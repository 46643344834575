* {
  box-sizing: border-box;
  line-height: normal;
}

body {
  font-family: "Roboto", sans-serif !important;
  margin: 0;
  padding: 0;
}

.dhiwise-code,
.dhiwise-navigation {
  min-height: 100%;
}

.common-pointer {
  cursor: pointer;
}

.dhiwise-navigation {
  padding: 20px;
  background-color: #232323;
}

.dhiwise-navigation h1 {
  color: white;
}

.dhiwise-navigation .headline {
  font-size: 14px;
  margin-bottom: 10px;
  color: white;
}

.dhiwise-navigation ul {
  padding: 0;
}

.dhiwise-navigation ul li {
  list-style: none;
  margin-right: 20px;
  font-size: 16px;
  font-weight: bold;
}

.dhiwise-navigation a {
  color: #132cdc;
}

table {
  border-spacing: 0;
  width: 100%;
}

.common-select {
  z-index: 10000;
}

.react-datepicker-popper {
  z-index: 100000 !important;
}

.drawable-icon {
  position: absolute;
  margin: auto;
  z-index: 1000;
}

.input-wrap {
  position: relative;
}

option {
  color: #000;
}

.table-wrap {
  overflow: auto;
}

input:focus {
  outline: none;
}

.ReactModal__Body--open {
  overflow: hidden;
}

.slider-indicator:first-child {
  margin-left: 0;
}

.slider-indicator:last-child {
  margin-right: 0;
}

.rc-drawer-content-wrapper {
  width: 100% !important;
}

.rc-drawer-right .rc-drawer-content-wrapper {
  display: flex;
  justify-content: flex-end;
}

.rc-drawer-left .rc-drawer-content-wrapper {
  display: flex;
  justify-content: flex-start;
}

.ReactModal__Overlay {
  z-index: 1051;
}

ul li a {
  display: block;
}

.mobile-menu div {
  height: 1.5px;
  width: 20px;
  background-color: #000;
  margin: 4px 0;
}

.mobile-menu {
  display: none;
  cursor: pointer;
}

.header-row {
  align-items: center;
  justify-content: space-between;
  display: flex;
}

.category-container .category-name {
  color: #00282D;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  max-width: 175px;
}

@media only screen and (min-width: 200px) and (max-width: 550px) {
  .mobile-menu {
    display: block;
  }

  .header-row {
    width: 100% !important;
  }

  .display-block {
    display: block;
  }

  .display-none {
    display: none;
  }

  .common-row-list>ul {
    padding-left: 10px;
    padding-right: 10px;
    flex-wrap: wrap;
  }
}

.menu-close-icon {
  position: absolute;
  right: 16px;
  top: 16px;
}

.menu-overlay {
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.7;
  position: fixed;
  left: 0px;
  top: 0px;
}

.close-line1 {
  transform: translate(0, 6px) rotate(-45deg) skewX(0) skewY(0) scaleX(1) scaleY(1);
}

.close-line2 {
  transform: translate(0, 0) rotate(45deg) skewX(0) skewY(0) scaleX(1) scaleY(1);
}

.common-row-list>ul {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  flex-grow: 1;
}

.common-column-list>ul {
  display: flex;
  flex-direction: column;
  list-style-type: none;
}

.common-column-list li {
  width: 100%;
}

/* scroll */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background: transparent;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #6b6b6b;
  border-radius: 10px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-left: 10px;
  margin-right: 0;
  margin-top: 14px;
  width: 86%;
  max-width: 100%;
}

.inner-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  width: 100%;
}

.items-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  width: 100%;
  margin-top: 56px;
}

.items-container .singleFinisherItem {
  flex: 0 0 150px;
  min-width: 150px;
  position: relative ;
}

.items-container .edit-icon-container {
  position: absolute ;
  top: 5px ;
  right: 5px ;
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.items-container .singleFinisherItem:hover .edit-icon-container {
  opacity: 1;
  visibility: visible ;
}

.items-container .finisher_text {
  color: #05202B;
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  max-width: 143px;
}

.contarct-text .contract-text-date {
  color: #00282D;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.contarct-text .contract-text-id {
  color: #05202B;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.item {
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: calc(16.666% - 2px);
  box-sizing: border-box;
}

.item Img {
  min-height: 4rem;
  min-width: 4rem;
  object-fit: cover;
}

.item Text {
  font-size: 20px;
  line-height: 22px;
  text-align: center;
  color: #333;
  width: 100%;
  font-weight: 800;
}

@media (max-width: 768px) {
  .your-sidebar-class {
    width: 100%;
  }
}

.modal-header {
  border-bottom: none !important;
  font-size: 30px !important;
  display: flex;
  margin-top: 10px;
  color: #000;
  font-weight: 700;
  justify-content: center;
}

.modal-footer {
  border-top: none !important;
  display: flex;
  justify-content: center;
}

.modal-body {
  font-size: 20px;
}

.modelButton {
  min-width: 10rem;
  color: white;
  font-weight: 500;
}

.modelCloseButton {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: black;
}

.custom-file-input {
  display: none;
}

.custom-file-input-label {
  color: #007bff;
  cursor: pointer;
}

.finishesImg {
  width: 89px;
  height: 89px;
  margin-bottom: 8px;
}

/* .edit-icon-container {
  position: absolute;
  top: 5px;
  right: 5px;
} */

.edit-icon {
  color: white;
  width: 20px !important;
  height: 20px !important;
}

.edit-icon-shadow {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  width: 30px !important;
  height: 30px !important;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  cursor: pointer;
}

.css-dip3t8 {
  overflow-y: hidden !important;
}

.p-dialog .p-dialog-footer {
  border: 0px !important;
  background-color: white !important;
  color: black !important;
}

.p-dialog .p-dialog-header {
  border: 0px !important;
  background-color: white !important;
  color: black !important;
}

.p-dialog-title {
  font-size: 25px !important;
  font-weight: 700;
}

.p-confirm-dialog-message {
  font-size: 20px !important;
  font-weight: 600;
}

.p-confirm-dialog-reject {
  width: 70px !important;
  height: 40px !important;
  background-color: #696969 !important;
  color: rgb(255, 255, 255) !important;
  font-weight: 700 !important;
  padding: 3px !important;
  border-radius: 6px !important;
  margin-right: 1rem !important;
}

.p-confirm-dialog-accept {
  width: 70px !important;
  height: 40px !important;
  background-color: #035699 !important;
  color: rgb(255, 255, 255) !important;
  font-weight: 700 !important;
  padding: 3px !important;
  border-radius: 5px !important;
}

.p-dialog-header-icon {
  color: rgb(131, 131, 131) !important;
}

.p-dialog {
  width: 35rem !important;
  height: 15rem !important;
  border-radius: 6px !important;
}

.deleteButton {
  min-width: 10rem;
  color: white;
  font-weight: 500;
  background-color: red !important;
  margin-right: 40px;
}

.react-pdf__Page {
  height: 600px;
  overflow: scroll;
}

.link {
  color: blue;
  text-decoration: none;
}

.link:hover {
  color: rgb(69, 75, 167);
}

.topBarForMob {
  display: none;
}

.react-pdf__Page {
  width: 407px;
  height: 514px;
  margin-top: 103px;
  min-width: 0 !important;
  min-height: 0 !important;
}

.focused {
  box-shadow: 0 0 5px rgba(0, 68, 255, 0.952);
}

.submit-button:hover {
  background-color: #0581b3;
  color: #fff;
  border-radius: 0.5rem;
}

@media only screen and (max-width: 600px) {

  .deleteButton {
    margin-right: 4px;
  }

  .react-pdf__Page {
    width: 370px;
    height: 514px;
    margin-top: 103px;
    min-width: 0 !important;
    min-height: 0 !important;
  }

  #toast {
    position: fixed;
    top: 56px !important;
    right: 5px;
    width: 19rem;
    height: 2rem;
    border-radius: 10px;
    font-size: 14px;
  }

  .p-toast .p-toast-message .p-toast-message-content {
    padding: 8px;
  }

  .mainSidebar {
    display: none;
  }

  .mainSidebar.showSidebar {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
  }

  .topBarForMob {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }

  .mainFinisherWrapper {
    width: 100% !important;
  }

  /* Signin, Forgot Password and Reset Password style begin */
  .main-container {
    display: flex !important;
    flex-direction: column !important;
    padding-right: 0 !important;
  }

  .img-layout-container .img-layout {
    border-radius: 0 0 50px 50px;
    height: 322px;
    width: inherit;
  }

  .main-container .img-layout-container {
    border-radius: 0px;
    height: 322px;
  }

  .img-layout-container .logo-container {
    border-radius: 0 0 50px 50px;
  }

  .img-layout-container .logo-container p {
    margin-bottom: 30vh;
    margin-left: 2vh;
  }

  .img-layout-container .logo {
    width: 94px;
    height: 16px;
  }

  #logo {
    width: 150px;
    height: 36px;
  }

  .back-button {
    top: 21rem !important;
    left: 10px !important;
    margin-left: 0px !important;
    width: 63px;
  }

  .back-button .back-icon {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
  }

  .back-button .back-button-text {
    font-size: 2.3vh;
    margin-left: 8px;
    color: #051F28;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .forgot-password-body .title {
    color: #052029;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .form-container {
    width: 100%;
    padding: 5%;
  }

  .submit-button {
    width: 100%;
  }

  /* Signin, Forgot Password and Reset Password style end */
}

@media only screen and (max-width: 1042px) {
  .back-button {
    left: 48%;
  }
}

.back-button {
  position: absolute;
  top: 6%;
  left: 100%;
}

.user-name {
  font-family: 'Montserrat' !important;
  font-weight: 600 !important;
}

.custom-input {
  padding: 0.5rem;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s;
}

.custom-input:focus {
  box-shadow: 0 0 5px rgba(0, 68, 255, 0.952) !important;
}


